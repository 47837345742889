<template>
  <div style="width: 100%;height: 100%;" id="app">

    <div id="logo">
      <el-image src="kuyinLogo.png"></el-image>
    </div>
    <div id="ll3d" v-if="showLL3d">
      <el-button @click="showLL3d=false" class="el-icon-close" style="position: absolute;right: 22%;top: 15%"
                 circle></el-button>
      <iframe width="100%" height="480"
              :src="ll3dSrc"
              style="width: 100%;height: 100%;background: transparent;" frameborder="0" allowfullscreen
              mozallowfullscreen="true" webkitallowfullscreen="true" onmousewheel=""></iframe>

    </div>
    <div id="right-menu">
      <ul>
        <li @click="switchFullScreen" class="menu-item">
          <span class="iconfont">&#xe649;</span>
        </li>
        <li v-bind:class="{music:musicEnable}" @click="toggleMusic();" class="menu-item">
          <span class="iconfont">&#xe644;</span>
        </li>
        <li @click="showHelp = true" class="menu-item">
          <span class="iconfont">&#xe619;</span>
        </li>
      </ul>
    </div>
    <!--    <transition name="el-fade-in">-->
    <div v-show="isLoading" id="loadScreen">
      <div>
        <el-image src="kuyinLogo.png"></el-image>
        <el-progress :percentage="loadingPercent"></el-progress>
      </div>

    </div>
    <!--    </transition>-->

    <el-dialog
        title="操作提示"
        width="70%"
        :visible.sync="showHelp"
    >

      <slot></slot>
      <el-image src="help.png"></el-image>
    </el-dialog>
    <!--    <button id="fullscreen" @click="switchFullScreen">全屏</button>-->
    <canvas id="canvas" ref="canvas" style="width: 100%;height: 100%;">
    </canvas>
  </div>
</template>

<script>
import {Game} from "@/js/game";
import {HotSportManage} from "@/js/hotSportManage";
import Vue from "vue";
import {CustomLoadingScreen} from "@/js/customLoadingScreen";

export default {
  name: 'App',
  components: {},
  data() {
    return {
      showHelp: false,
      musicEnable: true,
      showLL3d: false,
      ll3dSrc: "https://www.ll3d.com/model/8a99686f5ebdc1ad015ec29d447e01bf?nobackground=true",
      isLoading: false,
      loadingPercent: 0
    }
  },
  methods: {
    switchFullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      }
      this.$root.$el.requestFullscreen({})
    },
    toggleMusic() {
      this.musicEnable = this.game.switchMusic()
    }
  },
  mounted() {
    this.game = new Game(document.getElementById("canvas"))
    // this.game.sportManage.onSportClickObservable.add(eventData => {
    //   console.log(eventData)
    //   this.ll3dSrc = eventData.url
    //   this.showLL3d = true
    // })
    this.game.loadingScreen.onDisplayObs.add(e => this.isLoading = true)
    this.game.loadingScreen.onHideObs.add(e => this.isLoading = false)
    this.game.loadingScreen.onPercentObs.add(e => this.loadingPercent = e)
  }
}
</script>

<style>

* {
  padding: 0;
  margin: 0;
}

body, html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

#ll3d {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

#loadScreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#loadScreen > div {


  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80%;
  height: 200px;
  margin: auto;

}

#loadScreen > div > .el-progress {
  margin: 50px;
}

#hotSportPanel {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 800;
  pointer-events: none;
  overflow: hidden;
}

.music {
  display: block;
  animation: music 2s linear infinite;
}

@keyframes music {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

#logo {
  position: fixed;
  width: 125px;
  left: 25px;
  top: 32px;
}


.menu-item {
  padding: 5px;
  font-size: 20px;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}

li {
  list-style-type: none;
}

#right-menu {
  position: fixed;
  display: -webkit-flex; /* Safari */
  width: 30px;
  height: 200px;
  /*z-index: 1000;*/
  top: 32px;
  right: 24px;
}

#canvas {
  /*z-index: 1;*/
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@font-face {
  font-family: 'iconfont';  /* Project id 3013934 */
  src: url('//at.alicdn.com/t/font_3013934_07ntygb2erri.woff2?t=1639447219663') format('woff2'),
  url('//at.alicdn.com/t/font_3013934_07ntygb2erri.woff?t=1639447219663') format('woff'),
  url('//at.alicdn.com/t/font_3013934_07ntygb2erri.ttf?t=1639447219663') format('truetype');
}

.iconfont {
  font-family: "iconfont", serif !important;
  font-size: 18px;
  padding: 8px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
</style>
