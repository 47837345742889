import {Observable, SceneLoader} from "@babylonjs/core";

export class CustomLoadingScreen {
    displayLoadingUI() {
        this.onDisplayObs.notifyObservers()
    }

    hideLoadingUI() {
        this.onHideObs.notifyObservers()
    }

    setLoadingPercent(percent) {
        this.percent = Math.round(percent)
        this.onPercentObs.notifyObservers(this.percent)

    }

    onDisplayObs: Observable = new Observable()
    onHideObs: Observable = new Observable()
    onPercentObs: Observable<number> = new Observable()

    constructor() {
        this.percent = 0
    }
}
