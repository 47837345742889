import {
    AudioEngine,
    Color3, CubeTexture, DefaultRenderingPipeline,
    Engine, Mesh,
    MeshBuilder, PBRMaterial,
    Scene,
    SceneLoader, Sound, Tags, Vector3
} from "@babylonjs/core";
import "@babylonjs/inspector"
import "@babylonjs/materials"

import {Player} from "@/js/player";
import {GalleryManage} from "@/js/gallery";
import {getQueryVariable} from "@/js/tool";
import {HotSportManage} from "@/js/hotSportManage";
import {CustomLoadingScreen} from "@/js/customLoadingScreen";

export class GameConfig {

}

class Game {
    engine: Engine
    scene: Scene
    canvas: HTMLCanvasElement
    player: Player
    enableMusic: true

    constructor(canvas: HTMLCanvasElement) {
        this.canvas = canvas
        this.engine = new Engine(this.canvas, true)
        this.scene = new Scene(this.engine)
        // this.sportManage = new HotSportManage(this.scene)
        this.loadingScreen = this.engine.loadingScreen = new CustomLoadingScreen()
        this.music = new Sound('music', '秋日的私语.mp3', this.scene, null, {
            loop: true,
            autoplay: true
        })


        this.player = new Player("player", new Vector3(0, 2, -5), this.scene)
        this.player.target = Vector3.Zero()
        this.player.attachControl(this.canvas)


        const ground = MeshBuilder.CreateGround("", {width: 1000, height: 1000})
        ground.checkCollisions = true
        ground.position.y = -0.1
        ground.visibility = 0


        this.scene.environmentTexture = new CubeTexture("environment.env", this.scene)
        this.scene.createDefaultSkybox(new CubeTexture("environment.env", this.scene))
        this.scene.ambientColor = Color3.White().scale(1)
        let pipeline = new DefaultRenderingPipeline("pipeLine", true, this.scene, this.scene.cameras)
        pipeline.imageProcessing.toneMappingEnabled = true
        pipeline.imageProcessing.vignetteEnabled = true
        pipeline.samples = 4
        pipeline.sharpenEnabled = false;
        pipeline.bloomEnabled = true;

        let templateId = getQueryVariable('tem_id')
        setTimeout(() => this.engine.displayLoadingUI(), 33) //为了让其他类能监听到加载开始的事件
        SceneLoader.Append("/template/" + templateId + "/", 'model.glb', this.scene, scene => {
                console.log("场景加载完成")
                this.initAlpha(scene)
                this.initLightMap(scene)
                this.initCollision(scene)
                // this.sportManage.update().then()
                new GalleryManage(this.scene)

            },
            (evt) => {
                let loadedPercent = 0;
                if (evt.lengthComputable) {
                    loadedPercent = (evt.loaded * 100 / evt.total)
                } else {
                    let dlCount = evt.loaded / (1024 * 1024);
                    loadedPercent = Math.floor(dlCount * 100.0) / 100.0;
                }
                this.loadingScreen.setLoadingPercent(loadedPercent)
                console.log(loadedPercent)

            }
        )


        this.scene.collisionsEnabled = true
        this.engine.runRenderLoop(this.tick)


        window.addEventListener("resize", e => this.engine.resize())
        window.addEventListener("keydown", ev => {
            if (ev.key === "i") {
                if (this.scene.debugLayer.isVisible()) {
                    this.scene.debugLayer.hide()
                } else {
                    this.scene.debugLayer.show({handleResize: true, embedMode: true})
                }
            }
        })
    }

    switchMusic() {
        if (this.music.isPlaying) {
            this.music.pause()
            return false
        } else {
            this.music.play()
            this.music.setVolume(1)
            return true
        }
    }


    initLightMap(scene) {
        scene.meshes.forEach(mesh => {
            if (mesh.material instanceof PBRMaterial) {
                if (mesh.material.emissiveTexture) {
                    mesh.material.lightmapTexture = mesh.material.emissiveTexture
                    mesh.material.useLightmapAsShadowmap = true
                    mesh.material.emissiveTexture = null
                    mesh.material.emissiveColor = Color3.Black()
                    mesh.material.ambientColor = Color3.White()
                }
            }
        })
    }

    initCollision(scene: Scene) {
        this.scene.getNodeByName("Collision").getChildMeshes().forEach(mesh => {
            mesh.checkCollisions = true
            if (mesh instanceof Mesh) {
                mesh.visibility = 0
            }
        })

    }


    tick = () => {
        this.scene.render()
    }


    initAlpha(scene) {
        scene.materials.forEach(mat => {
            if (mat instanceof PBRMaterial) {
                if (mat.alpha < 0.9) {
                    // mat.metallic = 0
                    // mat.roughness = 0
                    // mat.subSurface.isRefractionEnabled = true
                    // mat.subSurface.refractionIntensity = 0.8
                    mat.transparencyMode = 2
                }
            }
        })
    }
}

export {Game}
